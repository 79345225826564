<template>
    <div class="blank">
        <img src="../assets/images/empty.png">
        <p>{{msg}}</p>
    </div>
</template>
<script>
export default {
    props:{
        msg:String
    }
}
</script>
<style lang="scss" scoped>
.blank{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 160px;
        height: 160px;
    }
    p{
        margin: 0px;
        color: gray;
        font-size: 14px;
        margin-bottom: 20px;
    }
}
</style>