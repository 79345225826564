import axios from 'axios'
// const base_url = "http://39.105.98.245:8076"  //  测试环境
const base_url='https://pina.mhys.com.cn'  //视频直播的https请求
import { Message,MessageBox } from 'element-ui';
// const base_url = process.env.ADMIN_SERVER

let token = ""

let myHead = () => {
    if(sessionStorage.getItem("token")){
        token = sessionStorage.getItem("token")       
    }
    return axios.create({
        baseURL: base_url,
        headers:{
            token:token
        },
        timeout:2000000, //请求超时的时间
    });
}

//下载文件
let myHead1 = () => {
  if(sessionStorage.getItem("token")){
      token = sessionStorage.getItem("token")       
  }
  return axios.create({
      baseURL: base_url,
      responseType: 'arraybuffer', // 或者responseType: 'blob'
      headers:{
        'Content-Type': 'application/json',
        token:token
      },
      timeout:20000, //请求超时的时间
  });
}

export default {
  base_url,
  //导出
  export_:(url)=>{
    return new Promise((resolve,reject)=>{
      myHead1().get(url).then(res=>{
        resolve(res);
      },res=>{
        reject(res)
      })
    })
  },
  get_: (url,ok=false) => {
    return  new Promise((resolve,reject)=>{
      myHead().get(url).then(res=>{
        if(res.data.code!=0){
            Message({
                message: res.data.message||'请求失败',
                type: 'warning'
            })
        }else{ 
            if(ok){
              Message({
                message: '操作成功',
                type: 'success'
              })
            }        
            resolve(res.data);
        } 
      },res =>{  
        if(res.response.status==500 || res.response.data.message === "NOT_LOGIN"){
          MessageBox.alert(
            '操作超时，请重新登录',
            '提示', {
                confirmButtonText: '确定',
                type: 'warning'
            }
          ).then(() => {
              sessionStorage.clear()
              location.href = location.origin
              })
        }      
        reject(res)
      })
    })
  },
  post_: (url,body,ok=false) => {
    return new Promise((resolve,reject)=> {
      myHead().post(url, body).then(res => {
        if(res.data.code!=0){
            Message({
                message: res.data.message||'请求失败',
                type: 'warning'
            })
        }else{ 
            if(ok){
              Message({
                message: '操作成功',
                type: 'success'
              })
            }      
            resolve(res.data);
        } 
      },res =>{
         reject(res)
      })
    })
  },
  put_: (url,body,ok=false) => {
    return new Promise((resolve,reject)=> {
      myHead().put(url, body).then(res => {
        if(res.data.code!=0){
            Message({
                message: res.data.message||'请求失败',
                type: 'warning'
            })
        }else{  
            Message.closeAll() 
            if(ok){
              Message({
                message: '操作成功',
                type: 'success'
              })
            }    
            resolve(res.data);
        } 
      },res =>{
        reject(res);
      });
    })
  },
  del_: (url,body,ok=false) => {
    return new Promise((resolve,reject)=> {
      myHead().delete(url,body).then(res => {
        if(res.data.code!=0){
            Message({
                message: res.data.message||'请求失败',
                type: 'warning'
            })
        }else{   
            if(ok){
              Message({
                message: '操作成功',
                type: 'success'
              })
            }      
            resolve(res.data);
        } 
      },res =>{
        reject(res);
      });
    })
  }


}
