<template>
    <div class="headline"  :class="height?'maxheight':'minheight'">
        <p class="headTitle">{{msg}}</p>
    </div> 
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    height:{
      type:Boolean,
      default:true
    }
  }
}
</script>
<style scoped>
.headline{
    padding-top: 12px;
    background-color: #f8fbff;
    padding-left: 10px; 
}
.maxheight{
  height: 30px;
}
.minheight{
  height: 25px;
  font-size: 14px;
}
.headline .headTitle{ 
    margin: 0;
    padding: 0; 
    
    border-left: 3px solid #409eff;
    padding-left: 10px;
}
</style>
