import { Message,MessageBox} from 'element-ui';
const allFun={
    funHeight:(ele, hei)=>{
        function heightRes () {
          // 获取屏幕高度
          let clientHeight = document.documentElement.clientHeight || window.screen.availHeight ;
          ele.style.height = (clientHeight - hei) + 'px'
        }
        heightRes()
        window.onresize = function () {
          heightRes()
        }
    },
    checking:(obj)=>{
      for(let i in obj){
        if(!obj[i]){
          Message({
            message:"请选择参加双选会的岗位",
            type:"warning"
          })
          return false
        }
      }
      return true
    },
    clearForm:(arr)=>{
      return arr.map(e=>{
        e.value=''
      })
    },
    //重新打开窗口
    openNewWindow(name,that,roomId){
      const { href } = that.$router.resolve({
          name: name,
          query:{
            id:roomId
          }
      });
      window.open(href, "_blank");
    },
    //提示框
    getPromptBox(message,type='warning'){ 
      Message({
        message,type
      })
    },
    //确认框
    getConfirmBox(message){
      return MessageBox.confirm(`此操作将${message}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
    },
    

    
}

export {allFun}
