import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import Login from '../view/login'
const router = new Router({
    routes:[
        {
            name:"Login",
            path:"*",
            component:Login
        },
        {
            name:'Register',
            path:'/register',
            component:()=>import('../view/register.vue')
        },
        {
            name:'resumePerson',
            path:'/resumePerson',
            component:()=>import('../view/resume/resumePerson.vue')
        },
        {
            name:'videoTalk',
            path:'/videoTalk',
            component:()=>import('../view/videoTalk.vue')
        },
        {
            name:'Index',
            path:'/index',
            component:()=>import('../view/index.vue'),
            children:[
                {
                    name:'Position',
                    path:'/position',
                    
                    component:()=>import('../view/position/index.vue'), 
                },
                {
                    name:'home',
                    path:'/home',
                    component:()=>import('../view/home.vue'), 
                },
                {
                    name:'teahome',
                    path:'/teahome',
                    component:()=>import('../view/teahome.vue'), 
                },
                
                {
                    name:'doubleElection',
                    path:'/doubleElection',
                    component:()=>import('../view/doubleElection/index.vue'), 
                },
                {
                    name:'resume',
                    path:'/resume',
                    component:()=>import('../view/resume/index.vue'), 
                },
                {
                    name:'resumeDetail',
                    path:'/resumeDetail',
                    component:()=>import('../view/resume/resumeDetail.vue'), 
                },
                {
                    name:'interview',
                    path:'/interview',
                    component:()=>import('../view/interview/index.vue'), 
                },
                {
                    name:'companyInfo',
                    path:'/companyInfo',
                    component:()=>import('../view/companyInfo/index.vue'), 
                },
                {
                    name:'doubleManage',
                    path:'/doubleManage',
                    component:()=>import('../view/doubleManage/index.vue'), 
                },
                {
                    name:'companyReview',
                    path:'/companyReview',
                    component:()=>import('../view/companyReview/index.vue'),
                    
                },
                {
                    name:'review',
                    path:'/review',
                    component:()=>import('../view/companyReview/review.vue'), 
                    
                },
                {
                    name:'companyDetail',
                    path:'/companyDetail',
                    component:()=>import('../view/companyReview/companyDetail.vue'), 
                },
                {
                    name:'companyStore',
                    path:'/companyStore',
                    component:()=>import('../view/companyStore/index.vue'), 
                },
                {
                    name:'platform',
                    path:'/platform',
                    component:()=>import('../view/platform/index.vue'), 
                },
                {
                    name:'dbmodel',
                    path:'/dbmodel',
                    component:()=>import('../view/doubleManage/model.vue'), 
                },
                {
                    name:'resumeStore',
                    path:'/resumeStore',
                    component:()=>import('../view/resumeStore/index.vue'), 
                },
                {
                    name:'resumeStoreDetail',
                    path:'/resumeStoreDetail',
                    component:()=>import('../view/resumeStore/storeDetail.vue'), 
                },
                {
                    name:'dbShoolStatic',
                    path:'/dbShoolStatic',
                    component:()=>import('../view/doubleElectionStatic/dbShoolStatic.vue'), 
                },
                {
                    name:'dbClassStatic',
                    path:'/dbClassStatic',
                    component:()=>import('../view/doubleElectionStatic/dbClassStatic.vue'), 
                },
                {
                    name:'dbStatic',
                    path:'/dbStatic',
                    component:()=>import('../view/doubleElectionStatic/dbStatic.vue'), 
                },
            ]
        }
    ]
})

// 全局路由守卫


export default router;