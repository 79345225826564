<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  
}
</script>

<style scoped>
@import './assets/css/reset.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<style>
 .el-dialog__header span{
    font-size: 16px;
}
 .el-dialog__body{
    padding: 20px 20px;
}
.el-dialog__header{
    border-bottom: 1px solid #EBEEF5;
}
.el-dialog__footer{
    border-top: 1px solid #EBEEF5;
}
.el-page-header__content{
    font-size: 16px !important;
}
</style>
