<template>
    <div class="pages" >
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="100"
            layout="prev, pager, next, jumper"
            :page-count="totalpage">
        </el-pagination>
    </div>
</template>
<script>

export default {
    data () {
        return {
            currentPage:1, 
        }
    },
    props: {totalpage:Number},
    methods: {
        current(){
            this.currentPage=1;
            this.handleCurrentChange(this.currentPage)
        },
        handleCurrentChange(val){
            this.$emit("getInitList",val-1);
            this.$emit("handle",val-1);
        },
        showpage(flag){  //flag:boolean - 是否显示翻页
            this.ifshow=flag
        }
    },
    watch: {
       addpage:function(){
           this.current()
       }
    }

}
</script>
<style scoped>
.pages{
    margin-top: 12px;
}
</style>


