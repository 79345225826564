<template>
    <div class="bg">
        <div class="logoImg">
            <img class="logo" src="../assets/images/ailogo.png"/>
        </div>
        <div class="center">
            <div class="left">
                <img src="../assets/images/employ.png"/>
            </div>
            <div class="right">
                <div class="rightBox">
                    <div class="topTitle">
                        欢迎登录      
                    </div>
                    <div class="inputBox">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" >
                            <el-form-item  prop="loginName">
                                <el-input
                                    type="text"
                                    placeholder="请输入手机号/moot账号"
                                    prefix-icon="el-icon-mobile-phone"
                                    v-model="ruleForm.loginName">
                                </el-input>
                            </el-form-item>
                            <el-form-item  prop="loginPass">
                                <el-input
                                    placeholder="请输入密码"
                                    prefix-icon="el-icon-key"
                                    v-model="ruleForm.loginPass"
                                    type="password">
                                </el-input>   
                            </el-form-item>  
                                  
                            <div class="drag" ref="dragDiv" >
                                <div class="drag_bg"></div>
                                <div class="drag_text">{{confirmWords}}</div>
                                <div ref="moveDiv" @mousedown="mousedownFn($event)" :class="{'handler_ok_bg':confirmSuccess}" class="handler handler_bg" style="position: absolute;top: 0px;left: 0px;"></div>
                            </div>
                            <el-form-item  prop="type">
                                <el-radio v-model="type" label="1">企业用户</el-radio>
                                <el-radio v-model="type" label="2">美和易思用户</el-radio>
                            </el-form-item>
                            <button  type="button" class="btn" @click="submitForm('ruleForm')">登录</button> 
                        </el-form> 
                    </div>
                    <div class="company">
                       <router-link to="/register"> <a> <i class="el-icon-right"></i>企业注册</a>
                       </router-link>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="footer">
            Copyright © 2019-2020 湖北美和易思教育科技有限公司. All rights reserved
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ruleForm: {
                loginName: '',
                loginPass:'',   
            },
            type:"1",
            rules: {
                loginName: [
                    // { validator: validateIphone, trigger: 'blur' },
                    {required: true, message: '请输入手机号或moot账号', trigger: 'blur' }
                ],
                loginPass:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ]
            },
            beginClientX:0,           /*距离屏幕左端距离*/
            mouseMoveStata:false,     /*触发拖动状态  判断*/
            maxwidth:'',               /*拖动最大宽度，依据滑块宽度算出来的*/
            confirmWords:'拖动滑块验证',   /*滑块文字*/
            confirmSuccess:false  
        }
    },
    methods:{       
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let obj={...this.ruleForm}
                        if(this.confirmSuccess){
                            let url=this.type==1?'/hr/user/login':'/moot/user/login'
                            this.$htp.post_(url,obj).then(res=>{
                                this.$router.push({path:'/index'})
                                if(this.type==1){
                                    sessionStorage.setItem('token',res.data.phoneNum)
                                    sessionStorage.setItem('nickName',res.data.userName)    
                                }else{
                                    sessionStorage.setItem('nickName',res.data.nickName)
                                    sessionStorage.setItem('userType',res.data.userType)
                                    sessionStorage.setItem('token',res.data.loginName)
                                    sessionStorage.setItem('isManager',res.data.isManager)
                                } 
                                sessionStorage.setItem('id',res.data.id)
                            })
                        }else{
                            this.$message({
                                type:"warning",
                                message:'请先滑动验证'
                            })
                        }     
                    } else {
                        return false;
                    }
                });
            },
            mousedownFn:function (e) {
                if(!this.confirmSuccess){
                    e.preventDefault && e.preventDefault();   //阻止文字选中等 浏览器默认事件
                    this.mouseMoveStata = true;
                    this.beginClientX = e.clientX;
                }
            },        //mousedoen 事件
            successFunction(){
                this.confirmSuccess = true
                this.confirmWords = '验证通过';
                
                if(window.addEventListener){
                    document.getElementsByTagName('html')[0].removeEventListener('mousemove',this.mouseMoveFn);
                    document.getElementsByTagName('html')[0].removeEventListener('mouseup',this.moseUpFn);
                }else {
                    document.getElementsByTagName('html')[0].removeEventListener('mouseup',()=>{});
                }
                document.getElementsByClassName('drag_text')[0].style.color = '#fff'
                document.getElementsByClassName('handler')[0].style.left = this.maxwidth + 'px';
                document.getElementsByClassName('drag_bg')[0].style.width = this.maxwidth + 'px';
            },                //验证成功函数
            mouseMoveFn(e){
                if(this.mouseMoveStata){
                    let width = e.clientX - this.beginClientX;
                    if(width>0 && width<=this.maxwidth){
                        document.getElementsByClassName('handler')[0].style.left = width + 'px';
                        document.getElementsByClassName('drag_bg')[0].style.width = width + 'px';
                    }else if(width>this.maxwidth){
                        this.successFunction();
                    }
                }
            },                   //mousemove事件
            moseUpFn(e){
                this.mouseMoveStata = false;
                var width = e.clientX - this.beginClientX;
                if(width<this.maxwidth){
                    document.getElementsByClassName('handler')[0].style.left = 0 + 'px';
                    document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px';
                }
            },
            
    },
    mounted(){
        
        this.maxwidth = this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth;
        document.getElementsByTagName('html')[0].addEventListener('mousemove',this.mouseMoveFn);
        document.getElementsByTagName('html')[0].addEventListener('mouseup',this.moseUpFn)
    },
}
</script>
<style scoped lang='scss'>
@import '../assets/css/login.scss';
</style>
<style>
.right .inputBox .el-input__inner{
    border-radius: 0px;   
}
.right .inputBox .el-form-item{
    margin-bottom: 25px;
}
</style>