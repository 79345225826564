<template>
    <div>
        <el-table :data="dataSource" 
       
        style="width: 100%" @selection-change="handleSelectionChange"
        :highlight-current-row='true'>
            <!--选择-->
            <el-table-column v-if="hasSelection" type="selection" width="55" ></el-table-column>
            <!--序号-->
            <el-table-column v-if="hasIndex" type="index" width="55" label="#"></el-table-column>
            <!--数据源-->
            <el-table-column v-for="(column, index) in columns"
                header-align="left"
                :sortable="column.hasSort"
                :key="index"
                :show-overflow-tooltip="true"
                :prop="column.prop"
                :label="column.label"
                :width="column.width?column.width:''"
                >
                <!-- v-if="column.isShow" -->
            </el-table-column>
            <!--操作-->
            <slot name="handleColumn"></slot>
        </el-table>
    </div>
</template>
<script>
export default {
    name:"tm-table",
    props:{
        //是否有多选框
        hasSelection:{
            type:Boolean,
            default:()=>{
                return false
            }
        },
        //是否有序号
        hasIndex:{
            type:Boolean,
            default:()=>{
                return true
            }
        },
        //相应字段的显示
        columns:{
            type:Array,
            default:()=>{
                return []
            }
        },
        //数据源
        dataSource:{
            type:Array,
            default:()=>{
                return []
            }
        },
    },
    methods:{
        //将选中的行发送到父组件
        handleSelectionChange(val) {
          const selectionArr = [];
          val.forEach(function (el) {
            selectionArr.push(el);
          });
        //   this.$emit('commitSelection', selectionArr);
        },
    }
}

</script>