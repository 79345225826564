<template>
    <div class="export">
        <el-button type="primary" 
        size="small" 
        icon="el-icon-download" @click="exportData">导出</el-button>
    </div>
</template>
<script>
export default {
    props:{
        exportUrl:{
            type:String,
            default:()=>{
                return ''
            }
        }
    },
    methods:{
        exportData(){
            let base_url=this.$htp.base_url
            let url=base_url+this.exportUrl
            this.$htp.export_(url).then((res)=>{
                // 创建Blob对象，设置文件类型
                const blob = new Blob([res.data], {
                    type: 'application/vnd.ms-excel'
                })
                const objectUrl = URL.createObjectURL(blob) // 创建URL
                window.location.href = objectUrl
                
            }) 
            
        }
    }
}
</script>