<template>
    <div>
        <el-table
            :data="tableData"
            border
            style="width: 100%"
        >
          <div v-for="(v,i) in dictionary" :key="i">
                <el-table-column
                :show-overflow-tooltip="true"
                :label="v.headname"
                :width="v.width"
                >
                    <template slot-scope="scope">
                        <div v-if="judge(v.key,scope.row[v.key],`tag`)">
                            <el-tag
                            :type="getTagInfo(v.tagType,scope.row[v.key]).type"
                            disable-transitions> 
                            {{getTagInfo(v.tagType,scope.row[v.key]).name}}
                            </el-tag>
                        </div>
                        
                        <div v-else-if="judge(v.key,scope.row[v.key],`button`)">
                            <el-button 
                            :type="scope.row[v.key]?scope.row[v.key].color:''"
                            size="mini"
                            @click="btnType(scope.row)"
                            >  
                            {{scope.row[v.key]?scope.row[v.key].text:''}}
                            </el-button>
                        </div>
                        <div v-else-if="judge(v.key,scope.row[v.key],`btnOrTag`)">
                            <el-button   
                            :type="scope.row[v.key].color"
                            size="mini"
                            @click="btnType(scope.row)"
                            >  
                            {{scope.row[v.key].text}}
                            </el-button>         
                        </div>
                        <span v-else>{{scope.row[v.key]}}</span>        
                    </template>
                </el-table-column>
          </div>  
        </el-table>
    </div>
</template>
<script>
export default {
    props:{
       tableData:{
           type:Array,
           default:()=>{return []}
       },
       dictionary:{
           type:Array,
            default:()=>{return []}
       } 
    },
    data() {
        return {
            tagTypeList:{
                state:[
                    {code:1,name:'未公开',type:'warning'},{code:2,name:'已公开',type:'primary'},
                    {code:3,name:'已公布',type:'success'},{code:4,name:'已结束',type:'info'} 
                ],
                isPass:[
                    {code:1,name:'未审核',type:'info'},{code:2,name:'已通过',type:'success'},
                    {code:3,name:'未通过',type:'danger'}  
                ]
            }
        }
    },
    methods: {
        //tag状态的类型
        getTagInfo(tagType,value){
            const List=this.tagTypeList[tagType]
            return List.find(e=>{return e.code==value})   
        },
        judge(key,item,name){
            return key==name && item?true:false
        },
        //按钮事件
        btnType(row){
            this.$emit('btnFun',row)
        },
    },
}
</script>
<style lang="scss" scoped>
.el-table{
    margin-top: 20px;
}
</style>