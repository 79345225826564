//岗位下拉
const positionPullList=
    [
        {
            "id": "1",
            "labelName": ".Net开发程序员"
        },
        {
            "id": "2",
            "labelName": "Java开发程序员"
        },
        {
            "id": "3",
            "labelName": "Android开发程序员"
        },
        {
            "id": "4",
            "labelName": "web前端工程师"
        },
        {
            "id": "5",
            "labelName": "实施工程师"
        },
        {
            "id": "6",
            "labelName": "测试工程师"
        },
        {
            "id": "7",
            "labelName": "IT运维支持工程师"
        },
        {
            "id": "8",
            "labelName": "软件销售"
        },
        {
            "id": "9",
            "labelName": "其他"
        }
]

//老师端数据属性List
let columnPropList=[
    {
        hasSort:true,
        prop:'deliveryNum',
        label:'总投递量',
        width:'100'
    },
    {
        hasSort:true,
        prop:'resumePassNum',
        label:'简历通过量',
        width:'120'
    },
    {
        hasSort:false,
        prop:'resumePassPoint',
        label:'简历通过率'
    },
    {
        hasSort:true,
        prop:'interviewNum',
        label:'实际面试量',
        width:'120'
    },
    {
        hasSort:false,
        prop:'toInterviewPoint',
        label:'实际赴约率'
    },
    {
        hasSort:true,
        prop:'passNum',
        label:'面试通过量',
        width:'120'
    },
    {
        hasSort:false,
        prop:'passPoint',
        label:'面试通过率'
    },
]
//公司薪资
const salaryList=[
    '面议','3k以下','3k-4k','4k-5k','5k-6k','6k-7k','7k-8k',
    '8k-9k','9k-10k','10k-11k','11k-12k','12k-13k'
]

//公司规模
const companyScaleList=[
    '50人以下','50-100人','100人以上'
]

//双选会状态
const statusList=[
    {
        name:"全部",
        type:'primary',
        status:0
    },
    {
        name:"未公开",
        type:'warning',  //element 标签对应的颜色
        status:1
    },
    {
        name:"已公开",
        type:'primary',
        status:2 
    },
    {
        name:"已公布",
        type:'success' ,
        status:3
    },
    {
        name:"已结束",
        type:'info',
        status:4
    },
]

//公司菜单
const companyMenuList=[
    {
        mainName:'首页',
        sub:[{name:'数据统计',path:'/home'}]
    },
    {
        mainName:'招聘',
        sub:[{name:'岗位',path:'/position'},{name:'双选会',path:"/doubleElection"},
        {name:'简历',path:'/resume'},{name:'面试',path:"/interview"}]
    },
    {
        mainName:'我的',
        sub:[{name:'企业信息',path:"/companyInfo"},{name:'平台信息',path:"/platform"}]
    }
]
//老师菜单
const teaMenuList=[
    {
        mainName:'首页',
        sub:[{name:'企业统计',path:'/teahome'},{name:'校区统计',path:'/dbShoolStatic'},
        {name:'双选会统计',path:'/dbStatic',show:1}]
    },
    {
        mainName:'双选会',
        sub:[{name:'双选会管理',path:'/doubleManage'},{name:'双选会审核',path:"/companyReview"}]
    },
    {
        mainName:'信息库',
        sub:[{name:'企业库',path:'/companyStore',show:1},{name:'简历库',path:"/resumeStore"},
        {name:"面试详情",path:"/resumeStoreDetail"}]
    }
]

export {salaryList,companyScaleList,companyMenuList,teaMenuList,
    statusList,positionPullList,columnPropList}

