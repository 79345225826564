import {statusList} from './config'
const statusShow=(status,ziduan='name')=>{
    let result;
    statusList.forEach(e=>{
        if(status==e.status){
            result=e[ziduan]
        }
    })
    return result
}

const getAge = (birthday)=>{
    if(birthday){
        let birthYear = birthday.split("-")[0];
        let d = new Date();
        let nowYear = d.getFullYear();
        let age = nowYear - birthYear;
        return age
    } 
}

//获取简历状态
const resumeStateArr=[
    {
        name:"未处理",
        color:"warning",
        state:[1]
    },
    {
        name:"不合适",
        color:"info",
        state:[2]
    },
    {
        name:"已邀约",
        color:"success",
        state:[3,4,5,6]
    }

]
//获取企业双选会的状态
const dbStateArr=[
    {
        name:"未通过",
        color:"danger",
        state:[3]
    },
    {
        name:"已通过",
        color:"success",
        state:[2]
    },
    {
        name:"审核中",
        color:"info",
        state:[1,4,5,6]
    }
]

const allStateArr=[
    {
        name:"未处理",
        color:"warning",
        state:[1]
    },
    {
        name:"不合适",
        color:"info",
        state:[2]
    },
    {
        name:"已邀约",
        color:"success",
        state:[3]
    },
    {
        name:"已通过",
        color:"success",
        state:[4]
    },
    {
        name:"未通过",
        color:"danger",
        state:[5]
    },
    {
        name:"未赴约",
        color:"info",
        state:[6]
    },
]
const getStatus=(state,type,resumeOrdb='resume')=>{
    let obj={}
    let arr=resumeOrdb=='all'?allStateArr:resumeOrdb=='resume'?resumeStateArr:dbStateArr
    arr.forEach(e=>{
        if(e.state.includes(state)){
            obj.name=e.name
            obj.color=e.color
        }
    })
    return obj[type]
}



export {statusShow,getAge,getStatus}