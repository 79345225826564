import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
import router from './router/index'
Vue.use(ElementUI);

import "./assets/icon/iconfont.css"
import "./assets/icon/iconfont.js"

//简历下载，html=>image=>base64=>pdf
import htmlToPdf from '../src/service/htmlToPdf.js'
Vue.use(htmlToPdf)

//注册全局组件
import headLine from './components/headLine'
Vue.component("headLine",headLine)
import blankPage from './components/404page'
Vue.component("blankPage",blankPage)
import paging from './components/paging'
Vue.component("paging",paging)
import commonTable from './components/commonTable'
Vue.component('commonTable',commonTable)

//注册全局table组件
import tmTable from './components/tmTable'
Vue.component('tm-table',tmTable)

//注册全局导出按钮组件
import commonExport from './components/commonExport'
Vue.component('commonExport',commonExport)

//过滤器
import * as filters from './service/filter'
Object.keys(filters).forEach(key=>{
  Vue.filter(key,filters[key])
})

//axios全局应用
import htp from './service/http'
Vue.prototype.$htp = htp

//tool全局应用
import {allFun} from './service/tool'
Vue.prototype.$allFun = allFun


Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
